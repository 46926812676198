const experience = [
  {
    id: 1,
    date: "Jun 2023 - Dec 2023",
    position: "Web Developer",
    company: "PT Eska Link",
    url: "https://eskalink.com/",
    description: [
      {
        id: 1,
        description:
          "Responsible for developing ERP (Enterprise Resource Planning) systems.",
      },
      {
        id: 2,
        description:
          "Report the results of the program that has been created to the team leader.",
      },
      {
        id: 3,
        description:
          "Contribute to brainstorming sessions for problem solving and idea development.",
      },
    ],
  },
  {
    id: 2,
    date: "Jan 2023 - Mar 2023",
    position: "Frontend Developer - Bootcamp",
    company: "Kawah Edukasi",
    url: "https://www.instagram.com/kawahedukasi.id",
    description: [
      {
        id: 1,
        description:
          "Kawah Edukasi's Frontend Developer Bootcamp is an intensive program designed to prepare participants for a career in the tech industry. Throughout the program, participants will learn essential skills such as HTML, CSS, JavaScript, Git, and React JS.",
      },
    ],
  },
  {
    id: 3,
    date: "May 2022 - Jan 2023",
    position: "Backend Developer - Online Course",
    company: "Dicoding Indonesia",
    url: "https://dicoding.com/",
    description: [
      {
        id: 1,
        description:
          "Dicoding Indonesia's is a online course focuses on equipping participants with the necessary skills to pursue a career in the tech industry. The course covers various topics, including JavaScript programming, the Hapi JS framework, and Amazon Web Services.",
      },
    ],
  },
];

export default experience;
