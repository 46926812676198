const awards = [
  {
    id: 1,
    title: "Backend Developer Learning Path - Dicoding Indonesia",
    date: "24 of Jan, 2023",
    image: "dicoding-expert.png",
    description: "",
    link: "https://www.dicoding.com/certificates/4EXG93L1DZRL",
  },
  {
    id: 2,
    title: "Frontend Developer - Kawah Edukasi",
    date: "17 of Mar 2023",
    image: "kawahedukasi.png",
    description: "",
    link: "https://drive.google.com/file/d/146HC7LgmV1sTEDS1mzyQjKt0fZFm1HVm/view?usp=sharing",
  },
  {
    id: 3,
    title: "React JS Pemula - Dicoding Indonesia",
    date: "25th of December, 2022",
    image: "dicoding-react.png",
    description: "",
    link: "https://www.dicoding.com/certificates/2VX3Y45J4PYQ",
  },
  {
    id: 4,
    title: "React + Redux",
    date: "12th of October, 2022",
    image: "react-redux.png",
    description: "",
    link: "https://www.sololearn.com/certificates/CT-8J4UK5JK",
  },
  {
    id: 5,
    title: "Cloud Practitioner Essentials (Belajar Dasar AWS Cloud)",
    date: "13th of Jun, 2022",
    image: "cloud.png",
    description: "",
    link: "https://www.dicoding.com/certificates/EYX4264DWZDL",
  },
];

export default awards;
