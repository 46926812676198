const projects = [
  {
    id: 1,
    title: "Booking Studio",
    image: "booking-studio.png",
    url: "https://heystudio.web.id/",
    category: "Web Development",
    tags: ["Laravel", "Bootstrap"],
    description:
      "A website dedicated to booking self-studio photography sessions. Users can reserve studio space, select desired equipment, and schedule their sessions conveniently online.",
  },
  {
    id: 2,
    title: "E-Shop",
    image: "eshop.png",
    url: "https://vzaliquid.com",
    category: "Web Development",
    tags: ["Laravel", "Next JS", "WA Web.JS", "API"],
    description:
      "An e-commerce platform specializing in selling vape liquids, where users can browse a wide range of products and add them to their shopping carts. With a streamlined checkout process, customers can easily purchase their selected items and complete their orders seamlessly.",
  },
  {
    id: 3,
    title: "Librarify",
    image: "librarify.png",
    url: "https://librarify-app.netlify.app/",
    category: "Web Development",
    tags: ["React JS", "Express JS"],
    description:
      "Librarify is an online platform dedicated to book management, allowing users to explore a diverse collection of books and add them to their favorites. Users can conveniently browse through the library's catalog, discover new titles, and organize their favorite reads for easy access.",
  },
  {
    id: 4,
    title: "Indonesia Tourisme",
    image: "indonesia-tourisme.png",
    url: "/",
    category: "Web Development",
    tags: ["HTML", "CSS", "Javascript"],
    description:
      "Indonesia Tourisme offers a captivating journey through Indonesia's beauty and culture, featuring an array of tourist attractions, cultural experiences, and a curated selection of hotels. Explore our platform to discover the best of Indonesia's destinations and find the perfect accommodation for your stay.",
  },
  {
    id: 5,
    title: "API Open Music",
    image: "json.png",
    url: "https://github.com/angkrnia/forum-api",
    category: "API",
    tags: ["Javascript", "Rabbit MQ", "Hapi JS", "Redis"],
    description:
      "The Open Music API is an advanced project from the Dicoding Indonesia expert-level online course. It offers access to a comprehensive music library with JWT authentication, RabbitMQ, and Redis integration. With streamlined CI/CD implementation, developers can deploy and integrate seamlessly.",
  },
];

export default projects;
