const skills = [
  {
    id: 1,
    title: "Backend Developer",
    image: "backend_blue.png",
    languages: [
      {
        id: 1,
        title: "PHP",
        css: "backend",
      },
      {
        id: 2,
        title: "Laravel",
        css: "backend",
      },
      {
        id: 3,
        title: "Hapi JS",
        css: "backend",
      },
      {
        id: 4,
        title: "Express JS",
        css: "backend",
      },
      {
        id: 5,
        title: "AWS",
        css: "backend",
      },
    ],
    description:
      "As a backend developer, I create and maintain server-side logic for web applications. My focus includes database management and ensuring smooth communication between the frontend and backend systems.",
  },
  {
    id: 2,
    title: "Frontend Developer",
    image: "frontend_blue.png",
    languages: [
      {
        id: 1,
        title: "JavaScript",
        css: "frontend",
      },
      {
        id: 2,
        title: "Vue.js",
        css: "frontend",
      },
      {
        id: 3,
        title: "React.js",
        css: "frontend",
      },
      {
        id: 4,
        title: "Bootstrap",
        css: "frontend",
      },
      {
        id: 5,
        title: "Tailwind",
        css: "frontend",
      },
    ],
    description:
      "As a frontend developer, I focus on creating user-friendly web interfaces using HTML, CSS, and JavaScript. My goal is to deliver visually appealing and intuitive experiences for users.",
  },
  {
    id: 3,
    title: "Android Developer",
    image: "android.png",
    languages: [
      {
        id: 1,
        title: "React Native",
        css: "android",
      },
      {
        id: 2,
        title: "Flutter",
        css: "android",
      },
    ],
    description:
      "As an Android developer using Flutter and React Native, I build cross-platform mobile apps for Android and iOS. My focus is on delivering efficient and visually appealing solutions.",
  },
];

export default skills;
