<template>
  <div class="col-sm-12 col-md-4 mb-3">
    <div class="text-center">
      <img
        class="mb-3"
        :src="require(`@/assets/images/${skill.image}`)"
        data-aos="fade-up"
        :data-aos-delay="delay"
        data-aos-anchor-placement="top-bottom"
      />
      <h4
        class="mb-3"
        data-aos="fade-up"
        :data-aos-delay="delay + 100"
        data-aos-anchor-placement="top-bottom"
      >
        {{ skill.title }}
      </h4>
      <div
        class="tags mb-3 has-text-weight-semibold"
        data-aos="fade-up"
        :data-aos-delay="delay + 200"
        data-aos-anchor-placement="top-bottom"
      >
        <span
          v-for="language in skill.languages"
          :key="language.id"
          class="tag"
          :class="language.css"
        >
          {{ language.title }}
        </span>
      </div>
      <div
        data-aos="fade-up"
        :data-aos-delay="delay + 300"
        data-aos-anchor-placement="top-bottom"
      >
        {{ skill.description }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SingleSkill",
  props: ["skill"],
  data: () => {
    return {
      delay: 300,
    };
  },
};
</script>
<style scoped>
img {
  width: 128px;
  height: 128px;
}

.tags .tag:not(:last-child) {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.tag:not(body) {
  align-items: center;
  background-color: #f5f5f5;
  color: #4a4a4a;
  display: inline-flex;
  font-size: 0.75rem;
  height: 2em;
  justify-content: center;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: nowrap;
}

span {
  -webkit-transition: 0.1s ease-in;
  -o-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}

span:hover {
  -webkit-transform: translate3d(0px, -5px, 0px);
  transform: translate3d(0px, -5px, 0px);
}

.frontend:hover {
  color: #fff;
  background-color: #60bcd4;
}

.backend:hover {
  color: #000;
  background-color: #ddcd4f;
}
.android:hover {
  color: #ffffff;
  background-color: #e96666;
}
</style>
