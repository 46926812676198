<template>
  <!-- Menu -->
  <MenuVue :links="links">&lt;Angkrnia<span class="prim">/</span>></MenuVue>
  <HeroSection id="hero" pic="header.png">
    <template v-slot:welcome>Hi! there👋</template>
    My name is <b>Angga Kurnia</b>. I'm a final year student who interested in
    <hi-word>web development</hi-word>. Is fond of problem-solving and
    interested in learning about new technology. I can quickly and efficiently
    analyze and solve problems.
    <template v-slot:buttons>
      <simple-button
        class="btn-primary text-white"
        url="mailto:anggakurnia712@gmail.com"
        icon="fa-regular fa-file-lines"
        >Contact me</simple-button
      >
    </template>
  </HeroSection>
  <SkillsGrid id="skills" :skills="skills" />
  <ExperienceTable id="edu-exp" :experience="experience" />
  <ProjectsGrid id="projects" :projects="projects" />
  <AwardsGrid id="awards" :awards="awards" />
  <FooterVue :links="socials" :copyright="copyright" />
</template>
<script>
import MenuVue from "@/components/shared/AppMenu.vue";
import FooterVue from "@/components/shared/AppFooter.vue";
import HeroSection from "@/components/hero/HeroSection.vue";
import SkillsGrid from "@/components/skills/SkillsGrid.vue";
import ExperienceTable from "@/components/experience/ExperienceTable.vue";
import AwardsGrid from "@/components/awards/AwardsGrid.vue";
import ProjectsGrid from "@/components/projects/ProjectsGrid.vue";

// data to fill components
import skills from "@/data/skills";
import experience from "@/data/experience";
import awards from "@/data/awards";
import projects from "@/data/projects";

import SimpleButton from "@/components/reusable/SimpleButton.vue";
import HiWord from "@/components/reusable/HighlightIt.vue";

export default {
  name: "HomeView",
  data: () => {
    return {
      delay: 100,
      links: [
        {
          name: "home",
          url: "#hero",
        },
        {
          name: "skills",
          url: "#skills",
        },
        {
          name: "experience",
          url: "#edu-exp",
        },
        {
          name: "projects",
          url: "#projects",
        },
        {
          name: "certificates",
          url: "#awards",
        },
      ],
      skills,
      experience,
      awards,
      projects,
      socials: [
        {
          id: 1,
          icon: "fab linkedin-in",
          url: "https://linkedin.com/in/angkrnia",
        },
        {
          id: 2,
          icon: "fab github",
          url: "https://github.com/angkrnia",
        },
      ],
      copyright: "2024 Angga Kurnia",
    };
  },
  components: {
    MenuVue,
    FooterVue,
    HeroSection,
    SkillsGrid,
    ExperienceTable,
    AwardsGrid,
    ProjectsGrid,
    SimpleButton,
    HiWord,
  },
};
</script>
<style lang="scss" scoped>
.prim {
  color: $primary;
}
</style>
