<template>
  <li class="timeline-item">
    <div class="timeline-info" data-aos="fade-right" :data-aos-delay="delay">
      <span>{{ job.date }}</span>
    </div>
    <div class="timeline-marker"></div>
    <div class="timeline-content">
      <h5
        class="timeline-title mb-md-3"
        data-aos="fade-left"
        :data-aos-delay="delay"
      >
        {{ job.position }} @
        <a v-if="job.url" :href="job.url" target="_blank">{{ job.company }}</a>
        <a v-if="!job.url">{{ job.company }}</a>
      </h5>
      <ul data-aos="fade-left" :data-aos-delay="delay + 100">
        <li v-for="desc in job.description" :key="desc.id">
          {{ desc.description }}
        </li>
      </ul>
    </div>
  </li>
</template>
<script>
export default {
  name: "SingleJob",
  props: ["job"],
  data() {
    return {
      delay: 300,
    };
  },
};
</script>
<style></style>
